import * as Styled from '../../../styles';
import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { HeaderH3, ParaText } from './richTextWithCenterDivStyles'
const RichTextWithCenterDiv = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <ParaText>{children}</ParaText>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <HeaderH3>{children}</HeaderH3>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.BodyH2>{children}</Styled.BodyH2>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle href={node?.data?.uri}>{children}</Styled.LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  return (
    <>
    <Styled.PageNotFoundInnerContainer>
        <HeaderH3>{sectionData?.header}</HeaderH3>
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
    </Styled.PageNotFoundInnerContainer>
    </>
  );
};

export default RichTextWithCenterDiv;
